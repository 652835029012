<!-- START firstScreen -->
<div [ngClass]="{'empresa': true}">
  <section class="firstScreen">
    <div class="container">
      <div class="firstScreen__inner">
        <h1 class="firstScreen__ttl" i18n>SUPERA LOS LÍMITES CON UN <br> HOSTING <br> PARA EMPRESAS</h1>
        <div class="firstScreen__sub_ttl" i18n>Supera los límites con recursos <br> exclusivos desde
          <span>{{ symbol }}</span>{{ hosting[2].plans[0].periods[0].price | currencyLocale}} anuales
        </div>
        <div class="wrapBtn">
          <button (click)="navigateToSection( 'pad' )" class="btn btnRed" mat-button i18n>Contrata aquí tu plan</button>
        </div>
      </div>
    </div>
  </section>
</div>
<!-- END firstScreen -->

<!-- START -->
<section class="pad bg_blue_grad2 pad_from_menu">
  <div class="container">
    <div class="sup_ttl2_white" i18n>Planes</div>
    <h2 class="ttl2_white mb" i18n>Menor precio, mayor capacidad</h2>

    <div class="wrapCardPrice col_4 pad_buttom">

      <div class="cardPrice" *ngFor="let plan of hosting[2].plans">
        <div class="cardPrice__top">
          <div class="cardPrice_ttl"></div>
          <div class="cardPrice__ttl">{{ plan.name }}</div>
          <p class="cardPrice__sub_ttl">{{ plan.sub }}</p>
          <div *ngFor="let period of plan.periods; let i = index">
            <p class="price price_ttal">
              <span *ngIf="i > 0" class="price_pre">
                <span class="currency valuta">{{ symbol }}</span>{{ plan.periods[i-1].price | currencyLocale }}</span>
              <span class="price_actual">
                <span class="currency valuta">{{ symbol }}</span>{{ period.price | currencyLocale }}</span>
            </p>
            <p class="price_sub" i18n *ngIf="i == 0">Anual + IVA</p>
            <p class="price_sub" i18n *ngIf="i > 0">Anual + IVA, al contratar por {{ period.period }}</p>
          </div>
        </div>

        <button id="empresa-contratar-{{plan.name}}" class="btn btnRed" mat-button (click)="goToUrl( plan.url )"
          i18n>contratar</button>

        <ul class="card_list">
          <li *ngFor="let feature of plan.features">
            <span [innerHTML]="feature.text"></span>
            <img src="{{ feature.icon_url }}" alt="{{ feature.text }}">
          </li>
        </ul>
      </div>

    </div>

  </div>

  <!-- START tapeBlock -->
  <app-block-domain [locale]="locale"></app-block-domain>
  <!-- END tapeBlock -->
</section>
<!-- END -->

<!-- START menuPlans-->
<app-menu-plans *ngIf="isShowPlans"></app-menu-plans>
<!-- END menuPlans-->

<!-- START blockList -->
<app-block-list [lists]="lists" [preRender]="false" (deferLoad)="showImage1 = true; addVideoControlsForMobyle()"></app-block-list>
<!-- END blockList -->

<!-- START videoCompany -->
<app-video-company [preRender]="false" (deferLoad)="showImage2 = true" [urlVideo]="urlVideo" [urlFoto]="urlFoto" [showImage]="showImage1"></app-video-company>
<!-- END videoCompany -->

<!-- START -->
<section class="pad_top bgMoreGrey">
  <div class="container">
    <div class="sup_ttl2_black" i18n>El hosting para tu EMPRESA</div>
    <h2 class="ttl2_black mb" i18n>Recursos Exclusivos</h2>

    <div class="wrapCollumns">
      <div class="wrapCollumns_column">
        <div class="wrapCollumns_column__img">
          <ng-container *ngIf="showImage1">
            <img  src="/assets/images/img4_column.svg" 
                  width="130px"
                  height="131px"
                  alt="Más rapidez">
          </ng-container>
        </div>
        <h3 class="ttl3 upper" i18n>Sitios más rápidos</h3>
        <p i18n>Configuración de almacenamiento en caché óptima que permite la carga de tu página en un abrir y
          cerrar de ojos.</p>
      </div>

      <div class="wrapCollumns_column">
        <div class="wrapCollumns_column__img">
          <ng-container *ngIf="showImage1">
            <img  src="/assets/images/img5_column.svg" 
                  width="130px"
                  height="130px"
                  alt="Más facilidad">
          </ng-container>
        </div>
        <h3 class="ttl3 upper" i18n>Métricas más accesibles</h3>
        <p i18n>Presta atención de manera sencilla a todas tus métricas importantes. Seguimiento de rendimiento y
          recopilación de información.</p>
      </div>

      <div class="wrapCollumns_column">
        <div class="wrapCollumns_column__img">
          <ng-container *ngIf="showImage1">
            <img  src="/assets/images/img6_column.svg" 
                  width="130px"
                  height="129px"
                  alt="Más seguridad">
          </ng-container>
        </div>
        <h3 class="ttl3 upper" i18n>Recursos más administrables</h3>
        <p i18n>Ten el control total sobre los recursos de tu sitio para que puedas monitorear y asignar recursos
          adicionales fácilmente.</p>
      </div>

      <div class="wrapCollumns_column">
        <div class="wrapCollumns_column__img">
          <ng-container *ngIf="showImage1">
            <img  src="/assets/images/img7_column.svg" 
                  width="130px"
                  height="117px"
                  alt="failover">
          </ng-container>
        </div>
        <h3 class="ttl3 upper center" i18n>Alta disponibilidad</h3>
        <p i18n>Cambiamos tu sitio de servidor si se presenta algún problema. Jamás estarás fuera de la web.</p>
      </div>

      <div class="wrapCollumns_column">
        <div class="wrapCollumns_column__img">
          <ng-container *ngIf="showImage1">
            <img  src="/assets/images/img8_column.svg"  
                  width="135px"
                  height="130px"
                  alt="seguridad">
          </ng-container>
        </div>
        <h3 class="ttl3 upper center" i18n>seguridad</h3>
        <p i18n>BlueHosting garantiza tu seguridad distribuyendo automáticamente tres copias de tus datos en varios
          dispositivos. ¡Siempre visible!</p>
      </div>

      <div class="wrapCollumns_column">
        <div class="wrapCollumns_column__img">
          <ng-container *ngIf="showImage1">
            <img  src="/assets/images/img9_column.svg" 
                  width="133px"
                  height="130px"
                  alt="CPANEL">
          </ng-container>
        </div>
        <h3 class="ttl3 upper center" i18n>CPANEL</h3>
        <p i18n>Cree nuevas cuentas de correo electrónico, administre subdominios y mucho más, todo con el clic de un
          botón.</p>
      </div>
    </div>

  </div>
</section>
<!-- END -->

<!-- START -->
<section class="pad" [preRender]="false" (deferLoad)="showSectionSlider = true">
  <div class="container">
    <div class="sup_ttl2_black" i18n>serás el amo de la galaxia</div>
    <h2 class="ttl2_black mb" i18n>Las ventajas de los planes Empresa</h2>

    <div class="content">
      <div class="blockAdvantage">
        <div class="wrap_img">
          <ng-container *ngIf="showImage2">
            <img src="/assets/images/img1_adv.svg" alt="imagen de conversión">
          </ng-container>
        </div>
        <div class="blockAdvantage__info">
          <h3 class="ttl3 upper" i18n>4 veces más rápido que un plan básico</h3>
          <ul class="list_check">
            <li i18n>Experimenta tiempos de carga hasta 4 veces más rápidos con servidores de baja densidad, hardware
              premium y múltiples capas de almacenamiento en caché.</li>
            <li i18n>Las solicitudes de contenido dinámico se procesan más rapidamente.</li>
            <li i18n>¡BlueHosting impulsa tu crecimiento!</li>
          </ul>
        </div>
      </div>

      <div class="blockAdvantage">
        <div class="wrap_img">
          <ng-container *ngIf="showImage2">
            <img src="/assets/images/img2_adv.svg" alt="imagen de tráfico">
          </ng-container>
        </div>
        <div class="blockAdvantage__info">
          <h3 class="ttl3 upper" i18n>Más Presencia, Más Recursos</h3>
          <ul class="list_check">
            <li i18n>Con un simple clic puedes aumentar tus recursos en la nube cuando crece tu presencia en línea.</li>
            <li i18n>Olvida la migración de datos, la inactividad o reinicios.</li>
            <li i18n> ¿Alto tráfico? no es un problema, para BlueHosting ¡Son buenas noticias!</li>
          </ul>
        </div>
      </div>

      <div class="blockAdvantage">
        <div class="wrap_img">
          <ng-container *ngIf="showImage2">
            <img src="/assets/images/img3_adv.svg" alt="imagen de recursos">
          </ng-container>
        </div>
        <div class="blockAdvantage__info">
          <h3 class="ttl3 upper" i18n>Estadísticas</h3>
          <ul class="list_check">
            <li i18n>Ya no necesitas un administrador de sistema o conocimiento avanzado. Accede fácilmente al
              rendimiento de tu sitio a través de un panel intuitivo, con útiles herramientas que proporcionan una vista
              rápida de las tendencias de uso, la velocidad de descarga, tiempo de actividad, alcance global y más.</li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- END -->

<!-- START blockClients -->
<ng-container *ngIf="showSectionSlider">
  <app-block-clients></app-block-clients>
</ng-container>
<!-- END blockClients -->

<!-- SRTART  -->
<section class="pad_top" [preRender]="false" (deferLoad)="showImage3 = true">
  <div class="container">
    <h2 class="ttl2_black" i18n>Elige tu plan</h2>
    <h3 class="ttl3 center" i18n>Selecciona el plan óptimo para tus necesidades comerciales</h3>
  </div>
  <app-tab-plans [hosting]="hosting"></app-tab-plans>
</section>
<!-- END  -->

<!-- START -->
<!-- <section class="pad_top">
  <div class="container">
    <h2 class="ttl2_black mb" i18n>BlueHosting, la velocidad de la luz a un clic de nube</h2>

    <div class="wrapCollumns">
      <div class="wrapCollumns_column">
        <div class="wrapCollumns_column__img">
          <img src="/assets/images/img4_column.svg" alt="Almacenamiento en caché integrado">
        </div>
        <h3 class="ttl3 upper ttl_height center" i18n>Almacenamiento en caché integrado</h3>
        <p i18n>Configuración de almacenamiento en caché óptima que permite la carga de tu página en un abrir y cerrar de ojos.</p>
      </div>

      <div class="wrapCollumns_column">
        <div class="wrapCollumns_column__img">
          <img src="/assets/images/img5_column.svg" alt="panel de control">
        </div>
        <h3 class="ttl3 upper ttl_height center" i18n>panel de control</h3>
        <p i18n>Presta atención de manera sencilla a todas sus métricas importantes. Seguimiento de rendimiento y recopilación de información.</p>
      </div>

      <div class="wrapCollumns_column">
        <div class="wrapCollumns_column__img">
          <img src="/assets/images/img6_column.svg" alt="Administracion de recursos">
        </div>
        <h3 class="ttl3 upper ttl_height center" i18n>Administracion de recursos</h3>
        <p i18n> Ten el control total sobre tu sitio, para que puedas monitorear y asignar fácilmente recursos adicionales .</p>
      </div>
    </div>
  </div>
</section> -->
<!-- END -->

<!-- START blockAttention -->
<section class="pad bg_blue5 blockAttention">
  <div class="container">
    <div class="wrapTxt">
      <h2 class="ttl2_white" i18n>¿Estás preparado para <br> llevar a tu empresa a <br> otro nivel?</h2>
      <button (click)="navigateToSection( 'pad' )" class="btn btnRed" mat-button i18n>CONTRATA AQUÍ TU PLAN</button>
    </div>
  </div>
  <div class="wrapImg">
    <ng-container *ngIf="showImage3">
      <img src="/assets/images/img_rocket.svg" alt="cyber day">
    </ng-container>
  </div>
</section>
<!-- END blockAttention -->

<!-- START -->
<section class="pad bg_grey" [preRender]="false" (deferLoad)="showImage4 = true">
  <div class="container">
    <div class="sup_ttl2_black" i18n>Características de nuestro servicio</div>
    <h2 class="ttl2_black" i18n>Tu experiencia digital comienza aquí</h2>
    <p class="center" i18n>BlueHosting no solo protege, sino que acelera miles de sitios web y otras aplicaciones de
      internet</p>
    <!-- START wrapCard -->
    <div class="wrapCard">
      <div class="wrapCard__item bg_blue1">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage3">
            <img src="/assets/images/img-card-item1.svg" alt="redimiento">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>RENDIMIENTO</h3>
          <p i18n>BlueHosting mejora el rendimiento de tu sitio con diversas tipologías de optimización.</p>
        </div>
      </div>

      <div class="wrapCard__item bg_blue2">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage3">
            <img src="/assets/images/img-card-item2.svg" alt="seguridad">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>SEGURIDAD</h3>
          <p i18n>Protege tu sitio y a quienes lo visitan de todo tipo de engaños on line. BlueHosting trabaja con WAF,
            DDoS y SSL
            para defender tu sitio</p>
        </div>
      </div>

      <div class="wrapCard__item bg_blue3">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage3">
            <img src="/assets/images/img-card-item3.svg" alt="confianza">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>CONFIANZA</h3>
          <p i18n>Administramos un servicio DNS con más de un 30% de cuota de mercado.</p>
        </div>
      </div>

      <div class="wrapCard__item bg_blue4">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage3">
            <img src="/assets/images/img-card-item4.svg" alt="visión">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>PREVENCIÓN</h3>
          <p i18n>Nuestra red es capaz de identificar visitantes y comportamientos de bots, que tecnologías de
            análisis convencionales
            no logran reconocer.</p>
        </div>
      </div>
    </div>
    <!-- END wrapCard -->
  </div>
</section>
<!-- END  -->

<!-- START block_txt_column -->
<section class="block_txt_column pad" [preRender]="false" (deferLoad)="showFooter = true">
  <div class="container">
    <h2 class="ttl2_black" i18n>Preguntas frecuentes</h2>
    <div class="txt_column">

      <div class="txt_column__column">
        <h3 class="ttl3" i18n>¿Puedo conectarme a mi base de datos de manera remota?</h3>
        <p i18n>Sí, en nuestra gama de planes Empresa, podrás efectuar una conexión remota a tu base de datos alojada en
          tu plan Web Hosting Empresa.</p>
        <h3 class="ttl3" i18n>¿Cómo agrego un dominio adicional a mi plan?</h3>
        <p i18n>Primero debes asegurarte de que tu plan cuenta con la opción de agregar más dominios. Si es así, solo
          deberás ingresar a las “herramientas de dominios adicionales” en tu cPanel en donde podrás completar los
          campos con los datos necesarios. Finalmente, deberás configurar los registros DNS de tu nuevo dominio. </p>
        <h3 class="ttl3" i18n>¿Puedo cambiar la versión de PHP?</h3>
        <p i18n>Sí, puedes seleccionar la versión de PHP que necesites para tu proyecto y que sea compatible con la
          herramienta que desees implementar. Para cambiarla, ingresa a tu cPanel y abre la herramienta “Selector de
          versiones de PHP”.</p>
      </div>

      <div class="txt_column__column">
        <h3 class="ttl3" i18n>¿Qué quiere decir disco SSD?</h3>
        <p i18n>El Solid-State Drive (SSD) es un disco duro que otorga mayor velocidad de procesamiento, lo que se
          traduce en webs más rápidas y procesos de datos más ágiles. Esto a su vez significa que las transacciones en
          tu sitio, se realizarán en tiempos mínimos. </p>
        <h3 class="ttl3" i18n>¿Puedo adquirir un plan sin un dominio registrado?</h3>
        <p i18n>Sí, pero ten en cuenta que nuestros planes de Web Hosting Empresa, incluyen el registro de un dominio
          .CL y que solo deberás preocuparte de que el dominio que quieres se encuentre disponible.</p>
        <h3 class="ttl3" i18n>¿Cómo configuro mis DNS?</h3>
        <p i18n>Configurar los registros DNS es necesario para poder enlazar tu dominio con nuestro servidor; de esta
          manera, al escribir el nombre de dominio en un navegador, este se traducirá en una dirección, la cual
          pertenece
          a nuestro servidor. Los registros DNS para los planes WebHosting Empresa son los siguientes:
        </p>
        <ol>
          <li>ns1.empresadns.net</li>
          <li>ns2.empresadns.net</li>
          <li>ns3.empresadns.net</li>
        </ol>
      </div>
    </div>
  </div>
</section>
<!-- END block_txt_column -->


<ng-container *ngIf="showFooter">
  <!-- START block_logo -->
  <app-block-logos></app-block-logos>
  <!-- END block_logo -->

  <app-footer></app-footer>
</ng-container>