<!-- START firstScreen -->
<div [ngClass]="{'cloud': true}">
  <section class="firstScreen">
    <div class="container">
      <div class="firstScreen__inner">
        <h1 class="firstScreen__ttl" i18n>¡CONSIGUE EL MEJOR VPS SSD <br> PARA <br> TU SITIO WEB!</h1>
        <div class="firstScreen__sub_ttl" i18n>Alcanza una gran velocidad <br> con Bluehosting</div>
        <div class="wrapBtn">
          <button (click)="navigateToSection( 'pad2' )" class="btn btnRed" mat-button i18n>Contrata aquí tu plan</button>
        </div>
      </div>
    </div>
  </section>
</div>
<!-- END firstScreen -->

<!-- START -->
<section class="pad2">
  <div class="container">
    <h2 class="ttl2_black">Nuestros Planes</h2>

    <div class="aboutPlanes__wrapper">
      <ng-template ngFor let-cloud_item [ngForOf]="clouds">
        <h3 class="ttl3">{{ cloud_item.cloud }}</h3>
        <p>{{ cloud_item.description }}</p>

        <table mat-table [dataSource]="cloud_item.types" class="aboutPlanes ts-aboutPlanes-1">

          <ng-container matColumnDef="memory">
            <th mat-header-cell *matHeaderCellDef> MEMORIA </th>
            <td mat-cell *matCellDef="let element">
              <div class="rel nowrap">
                <b>{{element.memory}}</b>
                <span class="label" *ngIf="element.popular">Popular</span>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="vcpus">
            <th mat-header-cell *matHeaderCellDef> VCPUS </th>
            <td mat-cell *matCellDef="let element">
              <div class="nowrap"> {{ element.vcpus }} </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="ssd">
            <th mat-header-cell *matHeaderCellDef> SSD DISCO </th>
            <td mat-cell *matCellDef="let element">
              <div class="nowrap"> {{ element.ssd }}</div>
            </td>
          </ng-container>
          <ng-container matColumnDef="traffic">
            <th mat-header-cell *matHeaderCellDef> TRÁFICO </th>
            <td mat-cell *matCellDef="let element">
              <div class="nowrap"> {{ element.traffic }} </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef class="center">
              <div class="td_flex">
                <div class="td_flex-inner">
                  <span i18n>PRECIO MENSUAL</span><span i18n><br>+ IVA</span>
                </div>
                <div class="td_flex-inner"></div>
              </div>
            </th>
            <td mat-cell *matCellDef="let element" class="center td_price">
              <div class="td_flex">
                <div class="td_flex-inner">
                  <b>{{ symbol }}{{ element.price | currencyLocale }}</b>
                </div>
                <div class="td_flex-inner">
                  <button id="cloud-comprar-{{cloud_item.id}}-{{element.price}}" (click)="goToUrl( element.url )" mat-button class="btnGreyTransp">Comprar</button>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="tablePlanesTitle"></tr>
          <tr mat-row *matRowDef="let row; columns: tablePlanesTitle;"></tr>

        </table>
      </ng-template>
    </div>
  </div>
</section>
<!-- END -->

<!-- START blockInfo -->
<section class="blockInfo bgBlueLight" [preRender]="false" (deferLoad)="showImage1 = true">
  <div class="container">
    <div class="blockInfo__wrap">
      <div class="blockInfo__txt w-auto">
        <h2 class="ttl2" i18n>¿Necesitas un VPS SSD Cloud Server de <br> aún más performance?</h2>
      </div>
      <button (click)="goToUrl('/contactar-con-ventas/.')" class="btn btnWhite" mat-button i18n>CONTACTA A UN EJECUTIVO</button>
    </div>
  </div>
</section>
<!-- END blockInfo -->

<!-- START blockList -->
<app-block-list-extra [lists]="lists"></app-block-list-extra>
<!-- END blockList -->

<!-- START -->
<section class="pad_top2" [preRender]="false" (deferLoad)="showImage2 = true; addVideoControlsForMobyle()">
  <div class="container">

    <div class="wrapCollumns row">
      <div class="wrapCollumns_column col_4">
        <div class="wrapCollumns_column__img">
          <ng-container *ngIf="showImage1">
            <img src="/assets/images/img1-column-1.svg" alt="Más rapidez">
          </ng-container>
        </div>
        <h3 class="ttl3 upper ttl_height" i18n>Rendimiento y performance</h3>
        <p i18n>Procesador Intel(R) Xeon(R) CPU E7-4880 v2 @ 2.50GHz creado para el alto rendimiento y la mayor capacidad de memoria por socket de la industria.</p>
      </div>

      <div class="wrapCollumns_column col_4">
        <div class="wrapCollumns_column__img">
          <ng-container *ngIf="showImage1">
            <img src="/assets/images/img2-column.svg" alt="Más seguridad">
          </ng-container>
        </div>
        <h3 class="ttl3 upper ttl_height" i18n>Mayor seguridad y protección</h3>
        <p i18n>Despreocúpate de los downtime, tus datos no corren riesgo alguno con nuestros planes de Cloud Server.</p>
      </div>

      <div class="wrapCollumns_column col_4">
        <div class="wrapCollumns_column__img">
          <ng-container *ngIf="showImage1">
            <img src="/assets/images/img6_column.svg" alt="Más facilidad">
          </ng-container>
        </div>
        <h3 class="ttl3 upper ttl_height" i18n>Monitoreo y alerta</h3>
        <p i18n>Recibe alertas vía SMS o correo electrónico con una simple y rápida personalización de los servicios de nuestro Cloud Server.</p>
      </div>

      <div class="wrapCollumns_column col_4">
        <div class="wrapCollumns_column__img">
          <ng-container *ngIf="showImage1">
            <img src="/assets/images/text-column/img16_dns_column.svg" alt="Más facilidad">
          </ng-container>
        </div>
        <h3 class="ttl3 upper ttl_height" i18n>DNS personalizado</h3>
        <p i18n>Crea de manera sencilla tu ID con DNS personalizados. Nuestro cloud server te da la opción desde tu panel.</p>
      </div>
    </div>

  </div>
</section>
<!-- END -->

<!-- START -->
<section class="pad_top bg_blue_dark_extra">
  <div class="container">

    <div class="wrapCollumns type2 row">
      <div class="wrapCollumns_column col_2">
        <div class="wrapCollumns_column__img">
          <ng-container *ngIf="showImage1">
            <img src="/assets/images/text-column/img17_column.svg" alt="Más rapidez">
          </ng-container>
        </div>
        <h3 class="ttl3 upper" i18n>MODO RESCATE</h3>
        <p i18n>Salva tu trabajo y archivos con el modo rescate facilmente desde tu panel de control con tan solo unos clics.</p>
      </div>

      <div class="wrapCollumns_column col_2">
        <div class="wrapCollumns_column__img">
          <ng-container *ngIf="showImage1">
            <img src="/assets/images/text-column/img_personalizacion.png" srcset="/assets/images/text-column/img-personalizacion@2x.png 2x" alt="Más seguridad">
          </ng-container>
        </div>
        <h3 class="ttl3 upper" i18n>PERSONALIZACIÓN</h3>
        <p i18n>Personaliza tu sitio web y almacena los cambios en la nube</p>
      </div>

      <div class="wrapCollumns_column col_2">
        <div class="wrapCollumns_column__img">
          <ng-container *ngIf="showImage1">
            <img src="/assets/images/text-column/img19_column.svg" alt="Más facilidad">
          </ng-container>
        </div>
        <h3 class="ttl3 upper" i18n>ESTADÍSTICAS TIEMPO REAL</h3>
        <p i18n>Estadísticas tiempo real. Revisa el comportamiento de tu Cloud Server SSD en tiempo real sin limitaciones ni dificultades y sin costos asociados.</p>
      </div>

      <div class="wrapCollumns_column col_2">
        <div class="wrapCollumns_column__img">
          <ng-container *ngIf="showImage1">
            <img src="/assets/images/text-column/img20_column.svg" alt="Más facilidad">
          </ng-container>
        </div>
        <h3 class="ttl3 upper" i18n>ACCESO VÍA CONSOLA</h3>
        <p i18n>Accede libremente a tu Cloud Server SSD vía consola, configura y maneja a gusto tu server sin limitaciones.</p>
      </div>
    </div>

  </div>
</section>
<!-- END -->

<!-- START videoCompany -->
<app-video-company [showImage]="showImage2"></app-video-company>
<!-- END videoCompany -->

<!-- START blockInfo -->
<app-block-info-simple [info]="info"></app-block-info-simple>
<!-- END blockInfo -->

<!-- START -->
<section class="pad bg_grey remov_pad_bottom">
  <div class="container">
    <div class="sup_ttl2_black" i18n>Características de nuestro servicio</div>
    <h2 class="ttl2_black" i18n>Tu experiencia digital comienza aquí</h2>
    <p class="center" i18n>BlueHosting no solo protege, sino que acelera miles de sitios web y otras aplicaciones de internet</p>
    <!-- START wrapCard -->
    <div class="wrapCard">
      <div class="wrapCard__item bg_blue1">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage2">
            <img src="/assets/images/img-card-item1.svg" alt="redimiento">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>RENDIMIENTO</h3>
          <p i18n>BlueHosting mejora el rendimiento de tu sitio con diversas tipologías de optimización.</p>
        </div>
      </div>

      <div class="wrapCard__item bg_blue2">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage2">
            <img src="/assets/images/img-card-item2.svg" alt="seguridad">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>SEGURIDAD</h3>
          <p i18n>Protege tu sitio y a quienes lo visitan de todo tipo de engaños on-line. BlueHosting utiliza WAF y SSL entre otras herramientas para defender tu sitio</p>
        </div>
      </div>

      <div class="wrapCard__item bg_blue3">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage2">
            <img src="/assets/images/img-card-item3.svg" alt="confianza">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>CONFIANZA</h3>
          <p i18n>Administramos un servicio DNS con más de un 30% de cuota de mercado.</p>
        </div>
      </div>

      <div class="wrapCard__item bg_blue4">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage2">
            <img src="/assets/images/img-card-item4.svg" alt="visión">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>PREVENCIÓN</h3>
          <p i18n>Nuestra red es capaz de identificar visitantes y comportamientos de bots que tecnologías de análisis convencionales no logran reconocer.</p>
        </div>
      </div>
    </div>
    <!-- END wrapCard -->
  </div>
</section>
<!-- END  -->

<!-- START block_txt_column -->
<section class="block_txt_column pad">
  <div class="container">
    <h2 class="ttl2_black" i18n>Preguntas frecuentes</h2>
    <div class="txt_column">

      <div class="txt_column__column">
        <h3 class="ttl3" i18n>¿Cómo adquiero una licencia cPanel?</h3>
        <p i18n>Solo debes contactar a tu agente de ventas para solicitarlo. Recuerda que cPanel es un producto adicional que se paga independientemente.</p>

        <h3 class="ttl3" i18n>¿Puedo cambiar el período de facturación de mi VPS SSD Cloud Server?</h3>
        <p i18n>Sí, para cambiar al ciclo que más te acomode, mensual, trimestral o semestral, contacta nuestro equipo de pagos y facturación para coordinar la forma que quieras.</p>

        <h3 class="ttl3" i18n>¿Cómo hacer un Rebuild?</h3>
        <p i18n>Inicia sesión en el área de cliente, escoge el Cloud Server y selecciona la opción “Rebuild”. Después de eso, podrás escoger un nuevo sistema operativo según tus requerimientos. Recuerda que un rebuild, implica borrar todos los archivos de tu cloud </p>
      </div>

      <div class="txt_column__column">
        <h3 class="ttl3" i18n>¿Los sistemas operativos Windows incluyen licencia?</h3>
        <p i18n>No proveemos licencias Windows. Los usuarios que deseen usar ese sistema operativo, deberán efectuar la comprar de la licencia directamente con Microsoft.</p>

        <h3 class="ttl3" i18n>¿Los servicios Cloud incluyen servicio de respaldo?</h3>
        <p i18n>Sólo el servicio VPS+Backup. Podemos guardar copias de seguridad mensuales, semanales y diarias con una retención por período, además de un snapshot que puede ser generado en cualquier momento.</p>
      </div>

    </div>
  </div>
</section>
<!-- END block_txt_column -->

<!-- START block_logo -->
<app-block-logos></app-block-logos>
<!-- END block_logo -->

<app-footer></app-footer>