import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL } from '../../environments/environment';
import { Observable } from 'rxjs';

export interface IInvoice {

}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient
  ) { }

  public findInvoice(formData) {
    const url = URL.FIND_INVOICE;
    return this.http.post(url, formData);
  }

  public sendContactFormBH(body): Observable<any> {
    const url = URL.SEND_CONTACT_BH;
    const headers = new HttpHeaders({
        "Content-Type": "text/plain;charset=utf-8"
      });
    return this.http.post(url, JSON.stringify(body), { headers });
  }
}
